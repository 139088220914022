<template>
  <div class="Enter-box">
    <div class="structure">
      <div class="Essential-box">
        <ul class="Essential-ul">
          <li @click="Essential()" class="">全部菜品</li>
          <li @click="structure()" class="Essential-li">菜品分类</li>
        </ul>
      </div>
      <div class="Essential-structure">
        <!-- 门店名称 -->
        <div class="Essential-structure-box">
          <ul>
            <li> <span> </span></li>
          </ul>
          <!-- 启停用 -->
          <div class="Essential-structure-choice">
            <el-radio-group v-model="radio">
              <el-radio @change="handlechange" label="1">启用</el-radio>
              <el-radio @change="handlechange1" label="0">停用</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- 三大功能 -->
        <div class="Essential-branch">
          <ul>
            <li @click="addcate();editcate1=0"><i></i>
              <h4>添加分类</h4>
            </li>
            <li @click="delcate()"><i></i>
              <h4>编辑</h4>
            </li>
          </ul>
        </div>
        <!-- 分类信息 -->
        <div class="Essential-table">
          <div class="mian_bao_xie">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for="(item,index) in mianbaoxie_list" :key="item.cat_id">
                <a v-if="mianbaoxie_list.length-1!=index" @click="mianbaoxie_tiao(item,index)">{{item.cat_name}}</a>
                <a v-else>{{item.cat_name}}</a>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <div class="food_fenlei">
            <el-table v-if="mianbaoxie_list[mianbaoxie_list.length-1].cat_name =='初始'" ref="multipleTable"
              :data="URLModles" tooltip-effect="dark" style="width: 100%" height="450"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="80">
              </el-table-column>
              <el-table-column prop="cat_name" width="240">
                <template slot-scope="scope">
                  <i @click="accessSubset(scope.row)">{{scope.row.cat_name}}</i>
                </template>
              </el-table-column>
              <el-table-column width="52">
                <i class="span_i"></i>
              </el-table-column>
              <el-table-column prop="prod_num" width="100" class="Essential-staff">
              </el-table-column>

              <el-table-column prop="rate_tax" width="800" class="Essential-staff">
                <template slot-scope="scope">
                  {{scope.row.rate_tax}} %
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button size="mini" :disabled="scope.$index === 0" @click="moveUp(scope.$index, scope.row)">
                    <i class="el-icon-arrow-up"></i>
                    <span>上移</span>
                  </el-button>
                  <el-button size="mini" :disabled="scope.$index === URLModles.length - 1"
                    @click="moveDown(scope.$index, scope.row)">
                    <i class="el-icon-arrow-down"></i>
                    <span>下移</span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="food_table">
            <el-table v-if="mianbaoxie_list[mianbaoxie_list.length-1].cat_name !='初始'" ref="multipleTable"
              :data="tableData" tooltip-effect="dark" style="width: 100%" height="450"
              @selection-change="handleSelectionChange">
              <el-table-column prop="prod_pic" label="图片" width="150">
                <template slot-scope="scope">
                  <img v-if="scope.row.prod_pic" :src="scope.row.prod_pic.split(' ')[0]" width="50px" height="50px" />
                  <div v-else>无图</div>
                </template>
              </el-table-column>
              <el-table-column prop="prod_code" label="编号" width="140">
              </el-table-column>
              <el-table-column prop="prod_name" label="菜品名称" width="150">
              </el-table-column>
              <el-table-column prop="cat_name" label="类型" width="140">
              </el-table-column>
              <el-table-column prop="site_name" label="仓库" width="150">
              </el-table-column>
              <el-table-column prop="unit" label="单位" width="140">
              </el-table-column>
              <el-table-column prop="date_valid" label="有效期" width="140">
              </el-table-column>
            </el-table>
          </div>


        </div>
      </div>
      <!-- 编辑分类 -->
      <el-drawer title="新增分类" :visible.sync="drawerCompile" ref="drawerCompile" :with-header="false">
        <div class="structureCharacter">
          <div class="Character-tltle">
            <span></span>
            <p>编辑</p>
          </div>
          <div class="Character-name">
            <p><span>*</span>分类名称</p>

            <el-input v-model="editcate" type="text" placeholder="请输入名称"></el-input>


          </div>
          <div class="Character-name">
            <p><span>*</span>税率（%）</p>

            <el-input v-model="editcate1" type="text" placeholder="请输入税率"></el-input>


          </div>

          <div class="admin-alter">
            <a class="admin-confirm" @click="CompileConfirm">确定</a>
            <a class="admin-cancel" @click="CompileCancel">取消</a>
          </div>
        </div>
      </el-drawer>
      <!-- 添加分类 -->
      <el-drawer title="编辑分类" :visible.sync="drawerS" ref="drawerS" :with-header="false">
        <div class="structureCharacter">
          <div class="Character-tltle">
            <span></span>
            <p>添加分类</p>
          </div>
          <div class="Character-name">
            <p><span>*</span>分类名称</p>
            <el-input v-model="part" type="text" placeholder="请输入名称"></el-input>
          </div>
          <div class="Character-name">
            <p><span>*</span>税率（%）</p>

            <el-input v-model="part1" type="text" placeholder="请输入税率"></el-input>


          </div>
          <div class="admin-alter">
            <a class="admin-confirm" @click="confirm">确定</a>
            <a class="admin-cancel" @click="cancel">取消</a>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
  // /insert_category_info // 新增产品类型
  import {
    insert_category_info,
    query_category_info_list,
    query_all_prod_list_of_category
  } from "@api/foodqiye";
  import {
    update_category_info,
    update_prod_category_active_batch,
    query_cat_list_active_0,
    query_ent_prod_cat_list_tree_of_parent
  } from "../../../api/pulic"

  export default {
    data() {
      return {
        radio: '1',
        part: '',
        part1: '',
        editcate: '',
        editcate1: 1,
        temsto: '',
        statuslist: [],
        URLModles: [],
        drawerS: false,
        editid: '',
        drawerCompile: false,
        mianbaoxie_list: [{
          cat_name: '初始',
          cat_id: ''
        }],
        tableData: [], // 当前菜品
      }
    },
    created() {
      this.JiChuXinXi()
    },
    methods: {
      // 页面初始进入 查询分类
      JiChuXinXi() {
        const ent_id = this.$store.state.ent_id
        console.log(ent_id);
        query_category_info_list({
          data: {
            ent_id,
            active: '1'
          }
        }).then((res) => {
          if (res.code == 10193) {
            this.URLModles = res.body.data
          } else {
            this.$message({
              message: '分类获取失败',
              type: 'error'
            })
          }
        })
      },
      handlechange() {
        let cat_ids = this.statuslist.map(i => {
          return i.cat_id
        })
        if (this.statuslist.length > 0) {
          update_prod_category_active_batch({
            data: {
              ent_id: this.$store.state.ent_id,
              user_id: sessionStorage.getItem('user_id'),
              active: '1', //1启用，0停用		
              cat_ids: cat_ids.join(',') //类型id,逗号分割
            }
          }).then(res => {
            this.JiChuXinXi()
          })
        } else {
          this.JiChuXinXi()
        }
      },
      handlechange1() {
        let cat_ids = this.statuslist.map(i => {
          return i.cat_id
        })
        if (this.statuslist.length > 0) {
          update_prod_category_active_batch({
            data: {
              ent_id: this.$store.state.ent_id,
              user_id: sessionStorage.getItem('user_id'),
              active: '0', //1启用，0停用		
              cat_ids: cat_ids.join(',') //类型id,逗号分割
            }
          }).then(res => {
            this.ChaXunTingyong()
          })
        } else {
          this.ChaXunTingyong()
        }

      },
      ChaXunTingyong() {
        query_cat_list_active_0({
          data: {
            ent_id: this.$store.state.ent_id,
            active: '0'
          }
        }).then(res => {
          this.URLModles = res.body.data
        })
      },
      delcate() {
        if (this.statuslist.length == 1) {
          this.drawerCompile = true
          this.editcate = this.temsto
          this.part1 = this.statuslist[0].rate_tax
            this.editcate1 = this.statuslist[0].rate_tax
          // this.part1=this.temsto.temsto
        } else {
          this.$message({
            message: '请选中一个分类',
            type: 'success'
          })
        }
      },
      addcate() {
        this.drawerS = true
        this.part1=0
        this.editcate1=0
        
      },
      cancel() {
        this.drawerS = false
      },
      CompileConfirm() {

        if (this.editcate1 <= 0) {
            this.$message({
              message: '税率需要大于0',
              type: 'success'
            })
          return
        }
        // 批量修改产品类型顺序
        update_category_info({
          data: {
            ent_id: this.$store.state.ent_id,
            user_id: sessionStorage.getItem('user_id'),
            cat_name: this.editcate, // 分类名
            rate_tax: String(this.editcate1),
            cat_id: this.editid,
            parent_id: '0',
            child_flag: '0'
          }
        }).then(res => {
          if (res.code == 10199) {
            this.drawerCompile = false // 关闭侧边
            this.JiChuXinXi()
            this.editcate = ''
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
          } else if(res.code == '500') {
            this.$message({
              message: '编辑失败',
              type: 'error'
            })
          } else if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
      },
      CompileCancel() {
        this.drawerCompile = false
      },
      Essential() {
        this.$router.push('/DishManagement')
      },
      structure() {
        console.log(1)
      },
      handleSelectionChange(val) {
        this.statuslist = val
        if (val[0] != undefined) {
          this.temsto = val[0].cat_name
          this.editid = val[0].cat_id
         this.part1 = val[0].rate_tax
          
          // this.part1 = val[0].rate_tax
        }
        console.log(val);
        console.log(val[0]);

      },
      // 上移
      moveUp(index, row) {
        var that = this
        console.log('上移', index, row)
        console.log(that.URLModles[index])
        if (index > 0) {
          const upDate = that.URLModles[index - 1]
          that.URLModles.splice(index - 1, 1)
          that.URLModles.splice(index, 0, upDate)
        } else {
          this.$message({
            message: '不能在移了',
            type: 'error'
          })
        }
      },

      // 下移
      moveDown(index, row) {
        var that = this
        console.log('下移', index, row)
        if (index + 1 === that.URLModles.length) {
          this.$message({
            message: '不能在移了',
            type: 'error'
          })
        } else {
          console.log(index)
          // 保存下一条数据
          const downDate = that.URLModles[index + 1]
          // 删除下一条数据
          that.URLModles.splice(index + 1, 1)
          // 增添被删除的那一条数据
          that.URLModles.splice(index, 0, downDate)
        }
      },
      // 添加分类
      confirm() {
        // // this.drawerS = false
        //  console.log('====',this.statuslist[0])
        // console.log('====',this.statuslist[0]!=undefined?this.statuslist[0].cat_id:'0');
        if (this.statuslist.length > 1) {
          this.$message({
            message: '请选择一个仓库',
            type: 'warning'
          })
        } else {
          insert_category_info({
            data: {
              ent_id: this.$store.state.ent_id,
              user_id: sessionStorage.getItem('user_id'),
              cat_name: this.part, // 分类名
              rate_tax: this.part1, // 分类名
              parent_id: this.statuslist[0] != undefined ? this.statuslist[0].cat_id : '0', // 父id
              child_flag: '0'
            }
          }).then((res) => {
            console.log(res);
            if (res.code == '10195') {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.JiChuXinXi()

              this.drawerS = false
              this.part = ''
              this.part1 = ''
            } else if (res.code == '500') {
              this.$message({
                message: '添加失败',
                type: 'error'
              })
              this.drawerS = false
              this.part = ''
              this.part1 = ''
            }else if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            }
          })
        }

      },
      // 进入子集分类
      accessSubset(item) {
        console.log('777777', item);
        this.mianbaoxie_list.push({
          cat_name: item.cat_name,
          cat_id: item.cat_id
        })
        let self = this
        // let data={
        //   ent_id:self.$store.state.ent_id, // 企业id
        //   parent_id:item.cat_id,
        //   active:'1'
        // }
        // query_category_info_list({data:data}).then((res)=>{
        //   console.log(res);
        //   this.URLModles=res.body.data
        // })
        let data = {
          ent_id: self.$store.state.ent_id, // 企业id
          cat_id: item.cat_id, // 类型id
        }
        query_all_prod_list_of_category({
          data: data
        }).then((res) => {
          console.log(res);
          let tableData = res.body.data
          this.tableData = tableData // 菜品赋值
        })
      },
      mianbaoxie_tiao(item, index) {
        console.log(item, index);
        this.mianbaoxie_list.splice(index + 1, this.mianbaoxie_list.length - (index + 1))
        let self = this
        let data = {
          ent_id: self.$store.state.ent_id, // 企业id
          parent_id: item.cat_id,
          active: '1'
        }
        query_category_info_list({
          data: data
        }).then((res) => {
          console.log(res);
          this.URLModles = res.body.data
        })
      }
    }
  }
</script>

<style scoed lang="scss">
  .el-main {
    // 
    background: #ffffff;
  }

  .Essential-table {
    width: 90%;
    margin: 0 auto;
  }

  .food_fenlei {
    .el-table__header-wrapper .el-table__header .has-gutter tr {
      display: none;
    }

    .Essential-table {
      width: 90%;
      margin: 0 auto;
      // .has-gutter th,
      // .has-gutter tr {
      //   display: none;
      // }
      // .el-table__row{
      //   .cell{
      //     .span_i {
      //       background-color: red;
      //       background: url('../../../assets/img/caiping.png') no-repeat;
      //       width: 22px;
      //       height: 15px;
      //       display: block;
      //     }
      //   }

      // }
      .span_i {
        background-color: red;
        background: url('../../../assets/img/caiping.png') no-repeat;
        width: 22px;
        height: 15px;
        display: block;
      }

      .mian_bao_xie {
        padding: 10px 0 10px 6px;
      }
    }
  }

  .structure {
    width: 100%;
    color: #f6f6f3;
    background: url('../../../assets/img/beijing2.png') no-repeat;

    background-size: 100% 800px;

    .Essential-box {
      padding: 0 0 0 0;
      display: flex;
      justify-content: space-between;

      .Essential-ul {
        display: flex;
        padding: 38px 0 0 97px;

        li {
          font-size: 20px;
          color: #666666;
          width: 280px;
          font-weight: 500;
        }

        .Essential-li {
          color: #fe5745;
        }
      }
    }

    .Essential-branch {
      width: 90%;
      height: 70px;
      margin: 20px auto 0 auto;
      background: linear-gradient(180deg, #fc4353, #ff5e41);
      border-radius: 15px 15px 0px 0px;

      ul {
        display: flex;
        margin-left: 50px;
        box-sizing: border-box;
        padding-top: 16px;

        li {
          font-size: 16px;
          color: #fff;
          width: 131px;
          height: 38px;
          border: 1px solid #ffffff;
          border-radius: 10px;
          display: flex;
          margin-right: 100px;
          justify-content: center;
          box-sizing: border-box;
          line-height: 26px;
          padding-top: 3px;

          i {
            margin-right: 10px;
          }

          &:first-of-type i {
            width: 28px;
            height: 29px;
            background: url('../../../assets/img/bian-1.png');
            background-size: 28px 29px;
            display: block;
          }

          &:nth-child(2) i {
            width: 28px;
            height: 29px;
            background: url('../../../assets/img/bian-2.png');
            background-size: 28px 29px;
            display: block;
          }

          &:last-of-type i {
            width: 20px;
            height: 21px;
            background: url('../../../assets/img/bian-3.png');
            background-size: 20px 21px;
            display: block;
            margin-top: 4px;
          }
        }
      }
    }

    .Essential-table {
      -moz-box-shadow: 0px 0px 10px #e6e6e8;
      -webkit-box-shadow: 0px 0px 10px #e6e6e8;
      box-shadow: 0px 0px 10px #e6e6e8;

      tbody {
        tr {
          &:nth-child(3) td .cell {
            display: flex;
          }
        }
      }
    }

    .Essential-staff {
      display: flex;
    }

    .Essential-structure {
      .Essential-structure-box {
        padding: 0 100px 0 100px;
        display: flex;
        justify-content: space-between;

        ul {
          display: flex;
          margin-top: 60px;

          li {
            color: #666;
            font-size: 18px;
            display: flex;
            margin-right: 100px;

            i {
              background: url('../../../assets/img/din.png') no-repeat;
              width: 23px;
              height: 30px;
              background-size: 23px 30px;
              display: block;
              margin-right: 10px;
            }

            span {
              margin-top: 4px;
            }
          }
        }

        .Essential-structure-choice {
          margin-top: 80px;
        }
      }

    }
  }



  .food_table {
    .el-table__body {
      width: 100% !important;
    }

    .el-table__header {
      width: auto !important;
    }
  }
</style>

<style>
  .food_table .has-gutter tr,
  .food_table .has-gutter th {
    /* background: linear-gradient(180deg, #FC4353, #FF5E41) !important; */
    color: #333;
    width: 240px;
  }

  .food_fenlei .span_i {
    background: url('../../../assets/img/caiping.png') no-repeat;
    width: 22px;
    height: 15px;
    display: block;
  }
</style>